'use client'
import useTranslation from 'next-translate/useTranslation'
import { RefObject, useRef, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import Select from 'react-select'

import { api } from '@/services/api'

import Loader from './Loader'

import { LocationInfo } from '@/types'

const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_AUTOCOMPLETE_KEY

const countryCodes = [
  {
    value: 'es',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2 ">
        <span>🇪🇸 Spain</span>
      </div>
    ),
  },
  {
    value: 'ad',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇦🇩 Andorra</span>
      </div>
    ),
  },
  {
    value: 'ar',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇦🇷 Argentina</span>
      </div>
    ),
  },
  {
    value: 'us',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇺🇸 US</span>
      </div>
    ),
  },
  {
    value: 'uy',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇺🇾 Uruguay</span>
      </div>
    ),
  },
  {
    value: 'co',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇨🇴 Colombia</span>
      </div>
    ),
  },
  {
    value: 'mx',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇲🇽 Mexico</span>
      </div>
    ),
  },
  {
    value: 'in',
    label: (
      <div className="flex min-w-[110px] pl-0 md:pl-2">
        <span>🇮🇳 India</span>
      </div>
    ),
  },
]

export type GoogleAutoCompleteWithCountryProps = {
  addressText?: string
  countryCode?: string
  type?: 'hangout' | 'user-location'
  onStateChange?: (state: 'loading' | 'done') => void
  onLocationChange: (info: LocationInfo) => void
}

function GoogleAutoCompleteWithCountry(props: GoogleAutoCompleteWithCountryProps) {
  const { t, lang } = useTranslation()

  const inputRef = useRef<HTMLInputElement | undefined>(undefined) as RefObject

  const { type = 'user-location', addressText = '', countryCode = null, onLocationChange, onStateChange } = props

  let defaultCountryCode = countryCodes[0]

  if (countryCode) {
    for (const cc of countryCodes) {
      if (cc.value === countryCode?.toLocaleLowerCase()) {
        defaultCountryCode = cc
        break
      }
    }
  }

  const [selectedOption, setSelectedOption] = useState(defaultCountryCode)
  const [locationApiLoading, setLocationApiLoading] = useState(false)
  const [error, setError] = useState('')

  const handleGoolglePlaceChange = async (result) => {
    // console.log({ result })

    if (result && !Object.hasOwn(result, 'place_id')) {
      setError(t(`Pick different location`))
      if (inputRef && inputRef.current) {
        inputRef.current.value = ''
      }
      return
    }

    setError('')
    setLocationApiLoading(true)
    if (onStateChange) onStateChange('loading')
    try {
      const cityResponse = await api.getLocationInfo<{ data: LocationInfo }>({ location: result })

      if (cityResponse.kind === 'ok') {
        onLocationChange(cityResponse.data.data)
      }
    } catch (e) {
      console.error('cityResponse error')
      console.error(e)

      // setSelectedAddressError(t(`gathering:Pick different location`) + ` ${name}, ${address}`)
    }

    setLocationApiLoading(false)
    if (onStateChange) onStateChange('done')
  }

  let queryTypes = ['addresss', 'locality']

  if (type === 'hangout') {
    queryTypes = ['establishment', 'address']
  }

  return (
    <div>
      <div className="border-text-400 focus:border-secondary-400 flex w-full flex-1 items-center rounded-[10px] border bg-transparent text-lg  font-normal focus:outline-none focus:ring-0">
        <div className="flex items-center text-lg">
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary50: '#F3B7BF',
                primary25: '#FAE2E5',
                primary: '#e0edef',
              },
            })}
            value={selectedOption}
            classNamePrefix="select-otp"
            options={countryCodes}
            blurInputOnSelect={true}
            isSearchable={false}
            onChange={(option) => {
              setSelectedOption(option)
            }}
          />
        </div>
        <Autocomplete
          ref={inputRef}
          apiKey={googleMapsApiKey}
          language={lang}
          defaultValue={addressText}
          placeholder={t(`City, Suburb or Zip Code`)}
          options={{
            types: queryTypes.join(','),
            fields: [],
            locationbias: 'ipbias',
            componentRestrictions: { country: selectedOption.value },
            // componentRestrictions: { country: ['es', 'ad', 'ar', 'us', 'co', 'uy', 'mx', 'in'] },
          }}
          onPlaceSelected={(place) => {
            handleGoolglePlaceChange(place)
          }}
          className="flex-1 rounded-2xl border-none focus:border-transparent focus:outline-none focus:ring-0"
        />
        {locationApiLoading && <Loader />}
      </div>
      {error && error.length > 0 && <span className="text-error-primary">{error}</span>}
    </div>
  )
}

export default GoogleAutoCompleteWithCountry
