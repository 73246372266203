import useTranslation from "next-translate/useTranslation";

import clsxm from "@/lib/clsxm";

export const ModalCloseButton = ({ className, onClose }: { onClose: CallableFunction; className: string }) => {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      role="dialog"
      onClick={() => onClose()}
      className={clsxm(
        'border-error-primary flex flex-row items-center justify-center rounded-[20px] border px-[14px] py-[7px]',
        'focus:outline-error-primary focus:ring-0',
        className,
      )}>
      <span className="text-error-primary text-base leading-4 font-medium">{t(`Close`)}</span>
      <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_687_16438)">
          <line
            x1="5.0314"
            y1="5.58105"
            x2="12.1499"
            y2="12.6996"
            stroke="#FF0000"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="4.84766"
            y1="12.5272"
            x2="11.9662"
            y2="5.40868"
            stroke="#FF0000"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_687_16438">
            <rect width="10" height="10.3525" fill="white" transform="translate(3.5 3.76123)" />
          </clipPath>
        </defs>
      </svg>
    </button>
  )
}
