'use client'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import clsxm from '@/lib/clsxm'

import { api } from '@/services/api'

import Loader from './Loader'

import { LocationInfo } from '@/types'

function FeaturedLocationsList({ onSelect }) {
  const [selectedLocation, setSelectedLocation] = useState<LocationInfo | null>(null)

  const featuredLocationsQuery = useQuery({
    queryKey: ['featuredLocationsQuery'],
    queryFn: () => api.getFeaturedLocations<{ data: LocationInfo[] }>(),
    // keepPreviousData: true,
    staleTime: 1000 * 3600,
  })

  const handleOnSelect = (location: LocationInfo) => {
    setSelectedLocation(location)
    onSelect(location)
  }

  if (featuredLocationsQuery.isLoading) {
    return (
      <div className="flex h-24 items-center justify-center">
        <Loader />
      </div>
    )
  }

  let list: LocationInfo[] = []

  if (featuredLocationsQuery.isSuccess && featuredLocationsQuery.data.kind === 'ok') {
    list = featuredLocationsQuery.data.data.data
  }

  return (
    <div className="flex flex-row flex-wrap justify-between  lg:justify-center">
      {list.map((location, index) => {
        return (
          <div key={index} className="flex basis-1/3 items-center justify-center lg:basis-0">
            <div
              onClick={() => handleOnSelect(location)}
              style={{ backgroundImage: `url(${location.coverImage})` }}
              className={clsxm(
                'bg-cream-300 relative mb-3 h-24 w-24 cursor-pointer rounded-2xl  bg-cover lg:mr-[14px] lg:h-28 lg:w-28',
                selectedLocation && selectedLocation.id === location.id && 'border-secondary-400 border opacity-20',
              )}>
              <span className="bg-cream-300 font-roboto absolute bottom-0 w-full rounded-b-2xl py-2 text-center text-xs font-medium tracking-wide">
                {location.cityName}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FeaturedLocationsList
